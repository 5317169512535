// Copyright (C) 2021. TANNER AG

export const TRANSLATION: { [locale: string]: { [key: string]: string } } = {
    de: {
        preview: "Vorschau",
        save: "Speichern",
        add: "Hinzufügen",
        create: "Erstellen",
        delete: "Löschen",
        documents: "Dokumente",
        created_at: "Erstellt am",
        updated_at: "Zuletzt aktualisiert",
        device_information: "Geräte-Informationen",
        downloads: "Downloads",
        qr_code: "QR-Code",
        qr_codes: "QR-Codes",
        qr_code_new: "Neuer QR-Code",
        print: "Drucken",
        cancel: "Abbrechen",
        add_document: "Dokument hinzufügen",
        base_device_new: "Neuer Geräte-Typ",
        base_device_exists: "Dieser Geräte-Typ existiert bereits.",
        generation_new: "Neue Generation",
        generation_new_info:
            "Beim Erstellen einer neuen Generation wird die aktuelle Generation des Geräte-Typs archiviert und kann nicht mehr angepasst werden. Dieser Vorgang kann nicht rückgängig gemacht werden.",
        last_modified: "Zuletzt geändert",
        filesize: "Dateigröße",
        filetype: "Dateityp",
        eq_no: "EQ-Nummer",
        model: "Geräte-Typ",
        language: "Sprache",
        status: "Status",
        search_placeholder: "EQ-Nummer oder Geräte-Typ ...",
        upload_text: "Datei hier rein ziehen, oder Klicken um eine Datei auszuwählen",
        default_documents: "Standard-Dokumente",
        sign_in: "Anmelden",
        sign_out: "Abmelden",
        email: "E-Mail",
        password: "Passwort",
        note: "Hinweis",
        archive: "Archiv",
        open: "offen",
        complete: "komplett",
        device_management: "Geräte-Verwaltung",
        user_management: "User-Verwaltung",
        firstName: "Vorname",
        lastName: "Nachname",
        user_new: "Neuer Benutzer",
        role: "Rolle",
        document_exists: "Ein Dokument mit diesem Namen existiert bereits.",
        options: "Optionen",
        copy: "Kopieren",
        copied_clipboard: "In Zwischenablage kopiert",
    },
};
