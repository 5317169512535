// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { formatBytes, formatTimestamp } from "../../lib/utils";
import FileUpload from "../FileUpload";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useFileUpload from "../../hooks/usefileupload";
import useTranslation from "../../hooks/usetranslation";
import Alert from "@mui/material/Alert";

type Props = {
    deviceId: string;
    filenames?: string[];
    onClose(): void;
};

const DeviceDetailsFileAdd: React.FC<Props> = ({ deviceId, onClose, filenames }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File>();
    const { loading, upload } = useFileUpload(deviceId);

    const fileNameExists = Boolean(file && filenames?.includes(file?.name));

    const handleDrop = (file: File[]) => {
        if (!file.length) {
            return;
        }

        setFile(file[0]);
    };

    const handleUpload = async () => {
        await upload(file);
        await onClose();
    };

    return (
        <>
            <DialogTitle> {t("add_document")}</DialogTitle>
            <DialogContent>
                {Boolean(file) ? (
                    <Stack>
                        <Typography variant="subtitle2">{file?.name}</Typography>
                        <Typography variant="caption">
                            {t("filesize")}: {formatBytes(file?.size || 0)}
                        </Typography>
                        <Typography variant="caption">
                            {t("filetype")}: {file?.type}
                        </Typography>
                        <Typography variant="caption">
                            {t("last_modified")}: {formatTimestamp(file?.lastModified?.toString() || "")}
                        </Typography>
                    </Stack>
                ) : (
                    <FileUpload onDrop={handleDrop} />
                )}
                {fileNameExists && <Alert severity="error">{t("document_exists")}</Alert>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleUpload} disabled={loading || !file || fileNameExists}>
                    {t("add")}
                </Button>
            </DialogActions>
        </>
    );
};

export default DeviceDetailsFileAdd;
