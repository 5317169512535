// Copyright (C) 2021 TANNER AG

import React from "react";
import DeviceDetailsFileDialog from "./DeviceDetailsFileDialog";
import DeviceDetailsFileAdd from "./DeviceDetailsFileAdd";

type Props = {
    deviceId: string;
    filenames?: string[];
};

const DeviceDetailsFile: React.FC<Props> = ({ deviceId, filenames }) => {
    return (
        <DeviceDetailsFileDialog>
            {({ onClose }) => <DeviceDetailsFileAdd deviceId={deviceId} onClose={onClose} filenames={filenames} />}
        </DeviceDetailsFileDialog>
    );
};

export default DeviceDetailsFile;
