// Copyright (C) 2021 TANNER AG

import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { Link, useSearchParams } from "react-router-dom";
import { formatTimestamp, Maybe } from "../../lib/utils";
import { ChevronRightIcon } from "../Icons";
import DeviceStatus from "../devicestatus/DeviceStatus";

type Props = {
    id: string;
    eqNo: string;
    productName?: string;
    updatedAt?: Maybe<string>;
    createdAt?: Maybe<string>;
    totalFiles: number;
    locale?: string;
    selected?: boolean;
    withBaseFiles?: boolean;
};

const DeviceListItem: React.FC<Props> = ({
    id,
    eqNo,
    productName,
    updatedAt,
    locale,
    totalFiles,
    createdAt,
    selected,
    withBaseFiles,
}) => {
    const [searchParams] = useSearchParams();
    return (
        <TableRow hover data-id={id} selected={selected}>
            <TableCell>{eqNo}</TableCell>
            <TableCell>{productName || "-"}</TableCell>
            <TableCell>{formatTimestamp(updatedAt || "")}</TableCell>
            <TableCell>{formatTimestamp(createdAt || "")}</TableCell>
            <TableCell padding="checkbox">
                <DeviceStatus
                    locale={locale}
                    totalFiles={totalFiles}
                    name={productName}
                    withBaseFiles={withBaseFiles}
                />
            </TableCell>
            <TableCell padding="none" align="right">
                <IconButton color="primary" component={Link} to={`?id=${id}&page=${searchParams.get("page") || 1}`}>
                    <ChevronRightIcon fontSize="small" />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default DeviceListItem;
