// Copyright (C) 2021 TANNER AG

import { Locale } from "../types";

const LOCALES: Locale[] = [
    {
        id: "de",
        displayName: "Deutsch",
    },
    {
        id: "en",
        displayName: "Englisch",
    },
    {
        id: "es",
        displayName: "Spanisch",
    },
    {
        id: "fr",
        displayName: "Französisch",
    },
];

const useLocales = () => {
    const getLocale = (id: string) => {
        return LOCALES.find((locale) => locale.id === id);
    };

    return { locales: LOCALES, getLocale };
};

export default useLocales;
