// Copyright (C) 2021 TANNER AG

import { useDeleteDeviceFileMutation } from "../graphql";

const useFileDelete = (deviceId: string) => {
    const [{ error, fetching }, executeDelete] = useDeleteDeviceFileMutation();

    const deleteFile = async (filename: string) => {
        await executeDelete({ id: deviceId, filename });
    };

    return { deleteFile, loading: fetching, error };
};

export default useFileDelete;
