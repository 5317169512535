// Copyright (C) 2021 TANNER AG

import React, { FormEvent, useRef } from "react";
import Stack from "@mui/material/Stack";
import LoginInput from "./LoginInput";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useAuth from "../../hooks/useauth";
import { LoginInputs } from "../../types";
import useTranslation from "../../hooks/usetranslation";

const LoginForm: React.FC = () => {
    const { t } = useTranslation();
    const { login, error } = useAuth();
    const formRef = useRef<HTMLFormElement>(null);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        // @ts-ignore
        const params: LoginInputs = Object.fromEntries(Array.from(data.entries()));

        if (!formRef?.current?.checkValidity()) {
            return;
        }

        if (!params.email || !params.password) {
            return;
        }

        await login(params);
    };

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <Stack spacing={2}>
                <LoginInput name="email" label={t("email")} type="text" margin="normal" variant="filled" />
                <LoginInput name="password" label={t("password")} type="password" margin="normal" variant="filled" />
                <Typography color="error">{error}</Typography>
                <Box sx={{ textAlign: "center" }}>
                    <Button type="submit" variant="contained" size="large">
                        {t("sign_in")}
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

export default LoginForm;
