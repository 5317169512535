// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Button from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "../Icons";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    url?: string;
};

const DevicePreview: React.FC<Props> = ({ url }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Button onClick={handleOpen} variant="outlined" size="small">
                {t("preview")}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <DialogTitle sx={{ display: "flex", alignItems: "center", py: 1 }}>
                    <Stack>
                        <span>{t("preview")}</span>
                        <Typography
                            sx={{ fontSize: "0.65rem", lineHeight: 1 }}
                            variant="caption"
                            component="a"
                            href={url}
                            target="_blank"
                        >
                            {url}
                        </Typography>
                    </Stack>
                    <IconButton onClick={handleClose} sx={{ marginLeft: "auto" }} edge="end">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <IFrame src={url} />
            </Dialog>
        </>
    );
};

const Dialog = styled(MuiDialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        maxWidth: 450,
        maxHeight: 700,
        height: "100%",
    },
}));

const IFrame = styled("iframe")(({ theme }) => ({
    border: "none",
    boxShadow: "none",
    height: "100%",
}));

export default DevicePreview;
