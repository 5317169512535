// Copyright (C) 2021 TANNER AG

import React from "react";
import DeviceDetailsFileDialog from "../devicedetails/DeviceDetailsFileDialog";
import BaseDeviceDetailsFileAdd from "./BaseDeviceDetailsFileAdd";

type Props = {
    deviceId: string;
    filenames?: string[];
};

const BasicDeviceDetailsFile: React.FC<Props> = ({ deviceId, filenames }) => {
    return (
        <DeviceDetailsFileDialog>
            {({ onClose }) => <BaseDeviceDetailsFileAdd deviceId={deviceId} onClose={onClose} filenames={filenames} />}
        </DeviceDetailsFileDialog>
    );
};

export default BasicDeviceDetailsFile;
