// Copyright (C) 2021 TANNER AG

import { useCallback } from "react";
import { TRANSLATION } from "../lib/translation";

type TranslationParams = {
    [name: string]: number | string;
};

const useTranslation = () => {
    const translations = TRANSLATION.de;

    const t = useCallback(
        (key: string, params?: TranslationParams): string => {
            if (!translations) {
                return "...";
            }

            if (translations.hasOwnProperty(key)) {
                let translation = translations[key];
                for (const parameter in params) {
                    if (!params.hasOwnProperty(parameter)) {
                        continue;
                    }
                    translation = translation.replace(`{${parameter}}`, params[parameter].toString());
                }

                return translation;
            }

            return "...";
        },
        [translations]
    );

    return { t };
};

export default useTranslation;
