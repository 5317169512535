// Copyright (C) 2021 TANNER AG

import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { formatTimestamp } from "../../lib/utils";
import Button from "@mui/material/Button";
import useTranslation from "../../hooks/usetranslation";
import { useDeleteUserMutation } from "../../graphql";

type Props = {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    selected?: boolean;
    updatedAt?: string;
    createdAt?: string;
    role?: string;
    onDelete?(): void;
};

const UserListItem: React.FC<Props> = ({
    id,
    email,
    firstname,
    role,
    onDelete,
    lastname,
    selected,
    updatedAt,
    createdAt,
}) => {
    const { t } = useTranslation();
    const [, deleteUser] = useDeleteUserMutation();

    if (email === "adminuser") {
        return null;
    }

    const handleDelete = async () => {
        await deleteUser({ id });
        onDelete?.();
    };

    return (
        <TableRow hover data-id={id} selected={selected}>
            <TableCell>{email}</TableCell>
            <TableCell>{firstname || "-"}</TableCell>
            <TableCell>{lastname || "-"}</TableCell>
            <TableCell>{formatTimestamp(updatedAt || "")}</TableCell>
            <TableCell>{formatTimestamp(createdAt || "")}</TableCell>
            <TableCell>{role}</TableCell>
            <TableCell padding="none" align="right">
                <Button size="small" variant="outlined" color="error" onClick={handleDelete}>
                    {t("delete")}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default UserListItem;
