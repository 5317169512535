// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import ProductSelect from "../ProductSelect";
import { ProductOption } from "../../types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useBaseDeviceExistsQuery, useCreateBaseDeviceMutation } from "../../graphql";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/usetranslation";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { routes } from "../../lib/utils";

type Props = {
    onClose(): void;
};

const CreateDeviceForm: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const [product, setProduct] = useState<ProductOption | null>(null);
    const [note, setNote] = useState("");
    const [{ fetching }, createDevice] = useCreateBaseDeviceMutation();
    const [{ data }] = useBaseDeviceExistsQuery({ variables: { productId: product?.id || "" }, pause: !product?.id });
    const navigate = useNavigate();

    const handleCreate = async () => {
        if (!product) {
            return;
        }

        const { data } = await createDevice({
            data: { note, ...(product && { product: { id: product.id, name: product.name } }) },
        });

        navigate(`${routes.devices}?id=${data?.createBaseDevice?.id}`);

        onClose();
    };

    const handleSelect = (selectedProduct: ProductOption | null) => {
        setProduct(selectedProduct);
    };

    const creationDisabled = data?.baseDeviceExists || !product;

    return (
        <>
            <DialogTitle>{t("base_device_new")}</DialogTitle>
            <DialogContent>
                <ProductSelect onChange={handleSelect} product={product} autoFocus />
                <TextField
                    label={t("note")}
                    fullWidth
                    margin="normal"
                    value={note}
                    onChange={(event) => setNote(event.currentTarget.value as string)}
                />
                {fetching && <Typography>Loading ...</Typography>}
                {data?.baseDeviceExists && <Alert severity="error">{t("base_device_exists")}</Alert>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleCreate} disabled={creationDisabled}>
                    {t("create")}
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateDeviceForm;
