// Copyright (C) TANNER AG 2021

import React, { useState, MouseEvent } from "react";
import { styled } from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { stringToColor } from "../lib/utils";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useAuth from "../hooks/useauth";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import useUser from "../hooks/useuser";
import useTranslation from "../hooks/usetranslation";

const User: React.FC = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { firstName, lastName, email } = useUser();
    const { logout } = useAuth();

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ListItem button onClick={handleOpen}>
                <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: stringToColor(`${firstName} ${lastName}`) }}>
                        {firstName?.charAt(0)}
                        {lastName?.charAt(0)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${firstName} ${lastName}`} />
            </ListItem>
            <Popover
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
            >
                <Stack p={2}>
                    <Typography sx={{ fontWeight: 500 }}>
                        {firstName} {lastName}
                    </Typography>
                    <Typography variant="caption">{email}</Typography>
                    <Button variant="outlined" size="small" onClick={() => logout()} sx={{ mt: 1 }}>
                        {t("sign_out")}
                    </Button>
                </Stack>
            </Popover>
        </>
    );
};

const Avatar = styled(MuiAvatar)(() => ({
    width: 30,
    height: 30,
    fontSize: 14,
}));

export default User;
