// Copyright (C) TANNER AG 2021

import { useEffect, useMemo } from "react";
import { useDeviceQuery, useUpdateDeviceMutation } from "../graphql";
import { useState } from "react";
import { ProductOption, Locale } from "../types";
import useLocales from "./uselocales";

const useDeviceUpdate = (id: string = "") => {
    const [{ data }] = useDeviceQuery({ variables: { id }, pause: !id });
    const [{ fetching }, executeUpdate] = useUpdateDeviceMutation();
    const { getLocale } = useLocales();

    const [baseDevice, setBaseDevice] = useState<ProductOption | null>(null);
    const [locale, setLocale] = useState<Locale | null>(null);
    const [eqNo, setEqNo] = useState("");
    const [note, setNote] = useState("");

    const updateDevice = async () => {
        await executeUpdate({
            id,
            data: {
                eqNo,
                note,
                ...(baseDevice && { baseDevice: { id: baseDevice.id, name: baseDevice.name } }),
                locale: locale?.id || "",
            },
        });
    };

    const updateWithBaseDownloads = async (checked: boolean) => {
        await executeUpdate({
            id,
            data: { withBaseFiles: checked },
        });
    };

    useEffect(() => {
        setEqNo(data?.device?.eqNo || "");
        setBaseDevice(data?.device?.baseDevice || null);
        setLocale(getLocale(data?.device?.locale || "") || null);
        setNote(data?.device?.note || "");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const updateAvailable = useMemo(
        () =>
            // eslint-disable-next-line eqeqeq
            eqNo != data?.device?.eqNo ||
            // eslint-disable-next-line eqeqeq
            baseDevice?.id != data?.device?.baseDevice?.id ||
            // eslint-disable-next-line eqeqeq
            locale?.id != data?.device?.locale ||
            // eslint-disable-next-line eqeqeq
            note != (data?.device?.note || ""),
        [eqNo, baseDevice, locale, data, note]
    );

    return {
        baseDevice,
        eqNo,
        locale,
        setBaseDevice,
        setEqNo,
        setLocale,
        updateDevice,
        fetching,
        updateWithBaseDownloads,
        data,
        updateAvailable,
        note,
        setNote,
    };
};

export default useDeviceUpdate;
