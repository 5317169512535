// Copyright (C) 2021 TANNER AG

import React from "react";
import DeviceDetailsDownloads from "./DeviceDetailsDownloads";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeviceDetailsQRCode from "./DeviceDetailsQRCode";
import { CloseIcon } from "../Icons";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useDeviceUpdate from "../../hooks/usedeviceupdate";
import TextField from "@mui/material/TextField";
import { formatTimestamp, Maybe } from "../../lib/utils";
import Paper from "@mui/material/Paper";
import LocaleSelect from "../LocaleSelect";
import DevicePreview from "../devicepreview/DevicePreview";
import useTranslation from "../../hooks/usetranslation";
import DeviceDetailsDelete from "./DeviceDetailsDelete";
import BaseDeviceSelect from "../BaseDeviceSelect";
import useUser from "../../hooks/useuser";

type Props = {
    id?: Maybe<string>;
    onClose(): void;
};

const DeviceDetails: React.FC<Props> = ({ id, onClose }) => {
    const { t } = useTranslation();
    const {
        eqNo,
        baseDevice,
        locale,
        setLocale,
        setEqNo,
        setBaseDevice,
        updateDevice,
        updateWithBaseDownloads,
        fetching,
        data,
        updateAvailable,
        note,
        setNote,
    } = useDeviceUpdate(id || "");
    const device = data?.device;
    const {isReadUser}= useUser();

    if (!id) {
        return null;
    }

    return (
        <>
            <Toolbar disableGutters sx={{ px: 2 }}>
                <IconButton edge="start" sx={{ mr: 1 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6">{device?.eqNo}</Typography>
                <Stack direction="row" sx={{ marginLeft: "auto" }}>
                    <Stack sx={{ marginLeft: "auto" }} spacing={1} direction="row" alignItems="center">
                        <DeviceDetailsDelete deviceId={id} />
                        <DevicePreview url={device?.url} />
                        <Button
                            variant="contained"
                            size="small"
                            disabled={fetching || !updateAvailable}
                            onClick={updateDevice}
                        >
                            {t("save")}
                        </Button>
                    </Stack>
                </Stack>
            </Toolbar>
            <Paper sx={{ mx: 2, px: 2, py: 1, mb: 2 }} variant="outlined">
                <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500 }} gutterBottom>
                    {t("device_information")}
                </Typography>
                <Stack sx={{ mb: 1 }}>
                    <Typography variant="body2">
                        {t("created_at")}: {formatTimestamp(device?.createdAt || "")}
                    </Typography>
                    <Typography variant="body2">
                        {t("updated_at")}: {formatTimestamp(device?.updatedAt || "")}
                    </Typography>
                </Stack>
                <TextField
                    value={eqNo}
                    onChange={(event) => setEqNo(event.currentTarget.value as string)}
                    label={t("eq_no")}
                    margin="normal"
                    disabled={isReadUser}
                    fullWidth
                />
                <BaseDeviceSelect onChange={setBaseDevice} baseDevice={baseDevice} />
                <LocaleSelect onChange={setLocale} locale={locale} />
                <TextField
                    value={note}
                    onChange={(event) => setNote(event.currentTarget.value as string)}
                    label={t("note")}
                    margin="normal"
                    disabled={isReadUser}
                    fullWidth
                />
            </Paper>
            <Paper sx={{ mx: 2, py: 1, mb: 2 }} variant="outlined">
                <DeviceDetailsDownloads
                    deviceId={device?.id}
                    files={device?.files}
                    baseFiles={device?.baseFiles?.filter((file) => file?.locale === locale?.id)}
                    withBaseFiles={data?.device?.withBaseFiles || false}
                    updateWithBaseDownloads={updateWithBaseDownloads}
                />
            </Paper>
            {device?.uid && (
                <Paper sx={{ mx: 2, px: 2, py: 1, mb: 2 }} variant="outlined">
                    <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500 }} gutterBottom>
                        {t("qr_code")}
                    </Typography>
                    <DeviceDetailsQRCode url={device?.url} />
                </Paper>
            )}
        </>
    );
};

export default DeviceDetails;
