// Copyright (C) 2021 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useUsersQuery } from "../graphql";
import Toolbar from "@mui/material/Toolbar";
import UserList from "../components/user/UserList";
import UserListItem from "../components/user/UserListItem";
import CreateUser from "../components/createuser/CreateUser";

const Users: React.FC = () => {
    const [{ data }] = useUsersQuery();
    return (
        <Box sx={{ display: "flex" }}>
            <Container maxWidth={false} sx={{ py: 2 }}>
                <Toolbar disableGutters>
                    <CreateUser />
                </Toolbar>
                <UserList>
                    {data?.users?.map((user) => (
                        <UserListItem
                            key={user.id}
                            id={user.id}
                            email={user.email}
                            firstname={user.firstName}
                            lastname={user.lastName}
                            createdAt={user.createdAt}
                            updatedAt={user.updatedAt}
                            role={user.role}
                        />
                    ))}
                </UserList>
            </Container>
        </Box>
    );
};

export default Users;
