// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import useQRCode from "../../hooks/useqrcode";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ClipboardIcon } from "../Icons";
import useTranslation from "../../hooks/usetranslation";
import ButtonBase from "@mui/material/ButtonBase";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

type Props = {
    url?: string;
};

const DeviceDetailsQRCode: React.FC<Props> = ({ url }) => {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();
    const { generateUrl } = useQRCode(url);

    const handlePrint = async () => {
        window.open(generateUrl(700, 4), "_blank");
    };

    const handleCopy = async () => {
        if (!window?.navigator?.clipboard?.writeText) {
            return;
        }

        await window?.navigator?.clipboard?.writeText(url ?? "");
        setCopied(true);
    };

    return (
        <>
            <Stack alignItems="center">
                <Stack sx={{ mb: 2 }} spacing={1}>
                    <ButtonBase
                        onClick={handlePrint}
                        sx={{ transition: "opacity 200ms ease-out", "&:hover": { opacity: 0.6 } }}
                    >
                        <Box
                            component="img"
                            src={generateUrl()}
                            alt={"qrcode"}
                        />
                    </ButtonBase>
                    <Button
                        onClick={handleCopy}
                        variant="contained"
                        size="small"
                        fullWidth
                        startIcon={<ClipboardIcon />}
                    >
                        {t("copy")}
                    </Button>
                </Stack>
            </Stack>
            <Snackbar
                open={copied}
                autoHideDuration={2000}
                onClose={() => setCopied(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: "100%" }}>
                    {t("copied_clipboard")}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DeviceDetailsQRCode;
