// Copyright (C) TANNER AG 2021

import { Maybe } from "./lib/utils";

export const PAGINATION_PAGE_SIZE = 30;

export type DeviceType = "standard" | "custom";

export type LoginInputs = {
    email: string;
    password: string;
};

export type LoginPayload = {
    token: string;
};

export type ProductOption = {
    id: string;
    name: string;
};

export type Product = ProductOption & {};

export type DeviceFile = {
    filename: string;
    url?: Maybe<string>;
    locale?: Maybe<string>;
};

export type Locale = {
    id: string;
    displayName: string;
};
