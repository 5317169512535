import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type BaseDevice = {
  __typename?: 'BaseDevice';
  archived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  files?: Maybe<Array<DeviceFiles>>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type BaseDeviceCreateInput = {
  note?: Maybe<Scalars['String']>;
  product?: Maybe<ProductInput>;
};

export type Device = {
  __typename?: 'Device';
  baseDevice?: Maybe<Product>;
  baseFiles?: Maybe<Array<DeviceFiles>>;
  createdAt?: Maybe<Scalars['String']>;
  eqNo: Scalars['String'];
  files?: Maybe<Array<DeviceFiles>>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  withBaseFiles?: Maybe<Scalars['Boolean']>;
};

export type DeviceCreateInput = {
  baseDevice?: Maybe<ProductInput>;
  eqNo: Scalars['String'];
};

export type DeviceFiles = {
  __typename?: 'DeviceFiles';
  filename: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type DeviceFilterInput = {
  query?: Maybe<Scalars['String']>;
};

export type DeviceUpdateInput = {
  baseDevice?: Maybe<ProductInput>;
  eqNo?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  withBaseFiles?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBaseDevice?: Maybe<BaseDevice>;
  createDevice?: Maybe<Device>;
  createUser?: Maybe<User>;
  deleteBaseDeviceFile?: Maybe<BaseDevice>;
  deleteDevice?: Maybe<Scalars['Boolean']>;
  deleteDeviceFile?: Maybe<Device>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  updateDevice?: Maybe<Device>;
  uploadBaseDeviceFile?: Maybe<BaseDevice>;
  uploadDeviceFile?: Maybe<Device>;
};


export type MutationCreateBaseDeviceArgs = {
  data: BaseDeviceCreateInput;
};


export type MutationCreateDeviceArgs = {
  data: DeviceCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteBaseDeviceFileArgs = {
  filename: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDeviceFileArgs = {
  filename: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDeviceArgs = {
  data: DeviceUpdateInput;
  id: Scalars['ID'];
};


export type MutationUploadBaseDeviceFileArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type MutationUploadDeviceFileArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  productGroup?: Maybe<ProductGroup>;
  thumbnail?: Maybe<Thumbnail>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  name: Scalars['String'];
};

export type ProductInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  baseDevice?: Maybe<BaseDevice>;
  baseDeviceExists: Scalars['Boolean'];
  baseDevices: Array<BaseDevice>;
  baseDevicesActive: Array<BaseDevice>;
  baseDevicesArchived: Array<BaseDevice>;
  baseDevicesCount: Scalars['Int'];
  device?: Maybe<Device>;
  devices: Array<Device>;
  devicesCount: Scalars['Int'];
  health?: Maybe<Scalars['String']>;
  products: Array<Product>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryBaseDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryBaseDeviceExistsArgs = {
  productId: Scalars['String'];
};


export type QueryBaseDevicesArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryBaseDevicesArchivedArgs = {
  productId: Scalars['String'];
};


export type QueryDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryDevicesArgs = {
  filter?: Maybe<DeviceFilterInput>;
  pagination?: Maybe<PaginationInput>;
};


export type QueryDevicesCountArgs = {
  filter?: Maybe<DeviceFilterInput>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  url: Scalars['String'];
};


export enum UseRole {
  Admin = 'Admin',
  Read = 'Read',
  Write = 'Write'
}

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role?: Maybe<UseRole>;
};

export type CreateBaseDeviceMutationVariables = Exact<{
  data: BaseDeviceCreateInput;
}>;


export type CreateBaseDeviceMutation = { __typename?: 'Mutation', createBaseDevice?: Maybe<{ __typename?: 'BaseDevice', id: string, uid: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, archived?: Maybe<boolean>, product?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number> }>> }> };

export type CreateDeviceMutationVariables = Exact<{
  data: DeviceCreateInput;
}>;


export type CreateDeviceMutation = { __typename?: 'Mutation', createDevice?: Maybe<{ __typename?: 'Device', id: string, uid: string, eqNo: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string>, baseDevice?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number> }>> }> };

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: Maybe<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, updatedAt?: Maybe<string>, createdAt?: Maybe<string>, role: string }> };

export type DeleteBaseDeviceFileMutationVariables = Exact<{
  id: Scalars['ID'];
  filename: Scalars['String'];
}>;


export type DeleteBaseDeviceFileMutation = { __typename?: 'Mutation', deleteBaseDeviceFile?: Maybe<{ __typename?: 'BaseDevice', id: string, uid: string, archived?: Maybe<boolean>, note?: Maybe<string> }> };

export type DeleteDeviceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDeviceMutation = { __typename?: 'Mutation', deleteDevice?: Maybe<boolean> };

export type DeleteDeviceFileMutationVariables = Exact<{
  id: Scalars['ID'];
  filename: Scalars['String'];
}>;


export type DeleteDeviceFileMutation = { __typename?: 'Mutation', deleteDeviceFile?: Maybe<{ __typename?: 'Device', id: string, uid: string, eqNo: string, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string> }> };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: Maybe<boolean> };

export type UpdateDeviceMutationVariables = Exact<{
  id: Scalars['ID'];
  data: DeviceUpdateInput;
}>;


export type UpdateDeviceMutation = { __typename?: 'Mutation', updateDevice?: Maybe<{ __typename?: 'Device', id: string, uid: string, eqNo: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string>, baseDevice?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number> }>> }> };

export type UploadBaseDeviceFileMutationVariables = Exact<{
  id: Scalars['ID'];
  file: Scalars['Upload'];
  locale?: Maybe<Scalars['String']>;
}>;


export type UploadBaseDeviceFileMutation = { __typename?: 'Mutation', uploadBaseDeviceFile?: Maybe<{ __typename?: 'BaseDevice', id: string, uid: string, archived?: Maybe<boolean>, note?: Maybe<string> }> };

export type UploadDeviceFileMutationVariables = Exact<{
  id: Scalars['ID'];
  file: Scalars['Upload'];
  locale?: Maybe<Scalars['String']>;
}>;


export type UploadDeviceFileMutation = { __typename?: 'Mutation', uploadDeviceFile?: Maybe<{ __typename?: 'Device', id: string, uid: string, eqNo: string, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string> }> };

export type BaseDeviceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BaseDeviceQuery = { __typename?: 'Query', baseDevice?: Maybe<{ __typename?: 'BaseDevice', id: string, uid: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, note?: Maybe<string>, archived?: Maybe<boolean>, product?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number>, locale?: Maybe<string>, url?: Maybe<string> }>> }> };

export type BaseDeviceExistsQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type BaseDeviceExistsQuery = { __typename?: 'Query', baseDeviceExists: boolean };

export type BaseDevicesQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;


export type BaseDevicesQuery = { __typename?: 'Query', devicesCount: number, baseDevices: Array<{ __typename?: 'BaseDevice', id: string, uid: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, note?: Maybe<string>, archived?: Maybe<boolean>, product?: Maybe<{ __typename?: 'Product', id: string, name: string }> }> };

export type BaseDevicesActiveQueryVariables = Exact<{ [key: string]: never; }>;


export type BaseDevicesActiveQuery = { __typename?: 'Query', baseDevicesActive: Array<{ __typename?: 'BaseDevice', id: string, product?: Maybe<{ __typename?: 'Product', id: string, name: string }> }> };

export type BaseDevicesArchivedQueryVariables = Exact<{
  productId: Scalars['String'];
}>;


export type BaseDevicesArchivedQuery = { __typename?: 'Query', baseDevicesArchived: Array<{ __typename?: 'BaseDevice', id: string, uid: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, note?: Maybe<string> }> };

export type DeviceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeviceQuery = { __typename?: 'Query', device?: Maybe<{ __typename?: 'Device', id: string, uid: string, eqNo: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string>, baseDevice?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number>, url?: Maybe<string> }>>, baseFiles?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string, size?: Maybe<number>, locale?: Maybe<string>, url?: Maybe<string> }>> }> };

export type DevicesQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
  filter?: Maybe<DeviceFilterInput>;
}>;


export type DevicesQuery = { __typename?: 'Query', devicesCount: number, devices: Array<{ __typename?: 'Device', id: string, uid: string, eqNo: string, createdAt?: Maybe<string>, updatedAt?: Maybe<string>, locale?: Maybe<string>, url?: Maybe<string>, withBaseFiles?: Maybe<boolean>, note?: Maybe<string>, baseDevice?: Maybe<{ __typename?: 'Product', id: string, name: string }>, files?: Maybe<Array<{ __typename?: 'DeviceFiles', filename: string }>> }> };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string, thumbnail?: Maybe<{ __typename?: 'Thumbnail', url: string }>, productGroup?: Maybe<{ __typename?: 'ProductGroup', name: string }> }> };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, role: string }> };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, role: string, updatedAt?: Maybe<string>, createdAt?: Maybe<string> }> };


export const CreateBaseDeviceDocument = gql`
    mutation CreateBaseDevice($data: BaseDeviceCreateInput!) {
  createBaseDevice(data: $data) {
    id
    uid
    createdAt
    updatedAt
    product {
      id
      name
    }
    files {
      filename
      size
    }
    archived
  }
}
    `;

export function useCreateBaseDeviceMutation() {
  return Urql.useMutation<CreateBaseDeviceMutation, CreateBaseDeviceMutationVariables>(CreateBaseDeviceDocument);
};
export const CreateDeviceDocument = gql`
    mutation CreateDevice($data: DeviceCreateInput!) {
  createDevice(data: $data) {
    id
    uid
    eqNo
    createdAt
    updatedAt
    baseDevice {
      id
      name
    }
    files {
      filename
      size
    }
    locale
    url
    withBaseFiles
    note
  }
}
    `;

export function useCreateDeviceMutation() {
  return Urql.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument);
};
export const CreateUserDocument = gql`
    mutation CreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
    email
    firstName
    lastName
    updatedAt
    createdAt
    role
  }
}
    `;

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
};
export const DeleteBaseDeviceFileDocument = gql`
    mutation DeleteBaseDeviceFile($id: ID!, $filename: String!) {
  deleteBaseDeviceFile(id: $id, filename: $filename) {
    id
    uid
    archived
    note
  }
}
    `;

export function useDeleteBaseDeviceFileMutation() {
  return Urql.useMutation<DeleteBaseDeviceFileMutation, DeleteBaseDeviceFileMutationVariables>(DeleteBaseDeviceFileDocument);
};
export const DeleteDeviceDocument = gql`
    mutation DeleteDevice($id: ID!) {
  deleteDevice(id: $id)
}
    `;

export function useDeleteDeviceMutation() {
  return Urql.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument);
};
export const DeleteDeviceFileDocument = gql`
    mutation DeleteDeviceFile($id: ID!, $filename: String!) {
  deleteDeviceFile(id: $id, filename: $filename) {
    id
    uid
    eqNo
    locale
    url
    withBaseFiles
    note
  }
}
    `;

export function useDeleteDeviceFileMutation() {
  return Urql.useMutation<DeleteDeviceFileMutation, DeleteDeviceFileMutationVariables>(DeleteDeviceFileDocument);
};
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
};
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($id: ID!, $data: DeviceUpdateInput!) {
  updateDevice(id: $id, data: $data) {
    id
    uid
    eqNo
    createdAt
    updatedAt
    baseDevice {
      id
      name
    }
    files {
      filename
      size
    }
    locale
    url
    withBaseFiles
    note
  }
}
    `;

export function useUpdateDeviceMutation() {
  return Urql.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument);
};
export const UploadBaseDeviceFileDocument = gql`
    mutation UploadBaseDeviceFile($id: ID!, $file: Upload!, $locale: String) {
  uploadBaseDeviceFile(id: $id, file: $file, locale: $locale) {
    id
    uid
    archived
    note
  }
}
    `;

export function useUploadBaseDeviceFileMutation() {
  return Urql.useMutation<UploadBaseDeviceFileMutation, UploadBaseDeviceFileMutationVariables>(UploadBaseDeviceFileDocument);
};
export const UploadDeviceFileDocument = gql`
    mutation UploadDeviceFile($id: ID!, $file: Upload!, $locale: String) {
  uploadDeviceFile(id: $id, file: $file, locale: $locale) {
    id
    uid
    eqNo
    locale
    url
    withBaseFiles
    note
  }
}
    `;

export function useUploadDeviceFileMutation() {
  return Urql.useMutation<UploadDeviceFileMutation, UploadDeviceFileMutationVariables>(UploadDeviceFileDocument);
};
export const BaseDeviceDocument = gql`
    query BaseDevice($id: ID!) {
  baseDevice(id: $id) {
    id
    uid
    createdAt
    updatedAt
    product {
      id
      name
    }
    files {
      filename
      size
      locale
      url
    }
    note
    archived
  }
}
    `;

export function useBaseDeviceQuery(options: Omit<Urql.UseQueryArgs<BaseDeviceQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaseDeviceQuery>({ query: BaseDeviceDocument, ...options });
};
export const BaseDeviceExistsDocument = gql`
    query BaseDeviceExists($productId: String!) {
  baseDeviceExists(productId: $productId)
}
    `;

export function useBaseDeviceExistsQuery(options: Omit<Urql.UseQueryArgs<BaseDeviceExistsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaseDeviceExistsQuery>({ query: BaseDeviceExistsDocument, ...options });
};
export const BaseDevicesDocument = gql`
    query BaseDevices($pagination: PaginationInput) {
  baseDevices(pagination: $pagination) {
    id
    uid
    createdAt
    updatedAt
    product {
      id
      name
    }
    note
    archived
  }
  devicesCount
}
    `;

export function useBaseDevicesQuery(options: Omit<Urql.UseQueryArgs<BaseDevicesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaseDevicesQuery>({ query: BaseDevicesDocument, ...options });
};
export const BaseDevicesActiveDocument = gql`
    query BaseDevicesActive {
  baseDevicesActive {
    id
    product {
      id
      name
    }
  }
}
    `;

export function useBaseDevicesActiveQuery(options: Omit<Urql.UseQueryArgs<BaseDevicesActiveQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaseDevicesActiveQuery>({ query: BaseDevicesActiveDocument, ...options });
};
export const BaseDevicesArchivedDocument = gql`
    query BaseDevicesArchived($productId: String!) {
  baseDevicesArchived(productId: $productId) {
    id
    uid
    createdAt
    updatedAt
    note
  }
}
    `;

export function useBaseDevicesArchivedQuery(options: Omit<Urql.UseQueryArgs<BaseDevicesArchivedQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BaseDevicesArchivedQuery>({ query: BaseDevicesArchivedDocument, ...options });
};
export const DeviceDocument = gql`
    query Device($id: ID!) {
  device(id: $id) {
    id
    uid
    eqNo
    createdAt
    updatedAt
    baseDevice {
      id
      name
    }
    files {
      filename
      size
      url
    }
    baseFiles {
      filename
      size
      locale
      url
    }
    locale
    url
    withBaseFiles
    note
  }
}
    `;

export function useDeviceQuery(options: Omit<Urql.UseQueryArgs<DeviceQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DeviceQuery>({ query: DeviceDocument, ...options });
};
export const DevicesDocument = gql`
    query Devices($pagination: PaginationInput, $filter: DeviceFilterInput) {
  devices(pagination: $pagination, filter: $filter) {
    id
    uid
    eqNo
    createdAt
    updatedAt
    baseDevice {
      id
      name
    }
    files {
      filename
    }
    locale
    url
    withBaseFiles
    note
  }
  devicesCount(filter: $filter)
}
    `;

export function useDevicesQuery(options: Omit<Urql.UseQueryArgs<DevicesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DevicesQuery>({ query: DevicesDocument, ...options });
};
export const ProductsDocument = gql`
    query Products {
  products {
    id
    name
    thumbnail {
      url
    }
    productGroup {
      name
    }
  }
}
    `;

export function useProductsQuery(options: Omit<Urql.UseQueryArgs<ProductsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProductsQuery>({ query: ProductsDocument, ...options });
};
export const UserDocument = gql`
    query User {
  user {
    id
    email
    firstName
    lastName
    role
  }
}
    `;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};
export const UsersDocument = gql`
    query Users {
  users {
    id
    email
    firstName
    lastName
    role
    updatedAt
    createdAt
  }
}
    `;

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UsersQuery>({ query: UsersDocument, ...options });
};