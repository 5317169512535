// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CreateDeviceForm from "./CreateDeviceForm";
import { AddIcon } from "../Icons";
import useTranslation from "../../hooks/usetranslation";
import useUser from "../../hooks/useuser";

const CreateDevice: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const {isReadUser}= useUser();

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
        <Button onClick={handleOpen} variant="contained" sx={{ mr: "auto" }} size="large" startIcon={<AddIcon />} disabled={isReadUser}>
                {t("qr_code_new")}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CreateDeviceForm onClose={handleClose} />
            </Dialog>
        </>
    );
};

export default CreateDevice;
