// Copyright (C) 2021 TANNER AG

import { useDeleteBaseDeviceFileMutation } from "../graphql";

const useBaseFileDelete = (deviceId: string) => {
    const [{ error, fetching }, executeDelete] = useDeleteBaseDeviceFileMutation();

    const deleteFile = async (filename: string) => {
        await executeDelete({ id: deviceId, filename });
    };

    return { deleteFile, loading: fetching, error };
};

export default useBaseFileDelete;
