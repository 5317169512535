// Copyright (C) 2021 TANNER AG

import React, { FormEvent, useRef } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useTranslation from "../../hooks/usetranslation";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useCreateUserMutation, UserCreateInput } from "../../graphql";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Radio, RadioGroup} from "@mui/material";

const FIELDS = [
    { name: "email", type: "email" },
    { name: "firstName", type: "text" },
    { name: "lastName", type: "text" },
    { name: "password", type: "password" },
];

type Props = {
    onClose(): void;
};

const CreateUserForm: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const [{ error }, createUser] = useCreateUserMutation();
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        // @ts-ignore
        const data: UserCreateInput = Object.fromEntries(Array.from(formData.entries()));

        if (!formRef?.current?.checkValidity()) {
            return;
        }

        const { error } = await createUser({ data });
        if (error) {
            return null;
        }

        onClose();
    };

    return (
        <>
            <DialogTitle>{t("user_new")}</DialogTitle>
            <form onSubmit={handleSubmit} ref={formRef}>
                <DialogContent>
                    {FIELDS.map(({ name, type }) => (
                        <TextField
                            key={name}
                            label={t(name)}
                            name={name}
                            type={type}
                            required
                            fullWidth
                            margin="normal"
                            autoComplete="off"
                        />
                    ))}
                     <RadioGroup name={"role"}>
                    <FormControlLabel control={<Radio value="Admin" />} label="Admin" />
                    <FormControlLabel control={<Radio value="Write" />} label="Write" />
                    <FormControlLabel control={<Radio value="Read" />} label="Read" />
                     </RadioGroup>
                    {error && <Alert severity="error">{error.message}</Alert>}
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button variant="outlined" onClick={onClose}>
                        {t("cancel")}
                    </Button>
                    <Button variant="contained" type="submit">
                        {t("create")}
                    </Button>
                </DialogActions>
            </form>
        </>
    );
};

export default CreateUserForm;
