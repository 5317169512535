// Copyright (C) 2021 TANNER AG

import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useTranslation from "../../hooks/usetranslation";

const BaseDeviceList: React.FC = ({ children }) => {
    const { t } = useTranslation();
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("model")}</TableCell>
                        <TableCell>{t("note")}</TableCell>
                        <TableCell>{t("updated_at")}</TableCell>
                        <TableCell>{t("created_at")}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default BaseDeviceList;
