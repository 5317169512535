// Copyright (C) 2021 TANNER AG

import { useUploadBaseDeviceFileMutation } from "../graphql";

const useBaseFileUpload = (deviceId: string) => {
    const [{ error, fetching }, executeUpload] = useUploadBaseDeviceFileMutation();

    const upload = async (file?: File, locale?: string) => {
        if (!file) {
            return;
        }

        await executeUpload({ id: deviceId, file, locale });
    };

    return { upload, loading: fetching, error };
};

export default useBaseFileUpload;
