// Copyright (C) TANNER AG 2021

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./hooks/useauth";
import { Provider } from "urql";
import { client } from "./lib/urql";
import Box from "@mui/material/Box";
import MenuBar from "./components/menubar/MenuBar";
import CustomDevices from "./views/CustomDevices";
import Footer from "./components/Footer";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import StandardDevices from "./views/StandardDevices";
import { routes } from "./lib/utils";
import Users from "./views/Users";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <Provider value={client}>
                    <Router>
                        <Box sx={{ display: "flex" }}>
                            <MenuBar />
                            <Box sx={{ flexGrow: 1 }}>
                                <Routes>
                                    <Route path={routes.qrCodes} element={<CustomDevices />} />
                                    <Route path={routes.devices} element={<StandardDevices />} />
                                    <Route path={routes.users} element={<Users />} />
                                    <Route path={routes.root} element={<Navigate to={routes.qrCodes} />} />
                                </Routes>
                                <Footer />
                            </Box>
                        </Box>
                    </Router>
                </Provider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
