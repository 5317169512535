// Copyright (C) TANNER AG 2021

import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import { Link } from "react-router-dom";
import { PAGINATION_PAGE_SIZE } from "../types";

type Props = {
    total: number;
    page: number;
};

const ListPagination: React.FC<Props> = ({ total, page }) => {
    const count = Math.ceil(total / PAGINATION_PAGE_SIZE);

    return (
        <Stack alignItems="flex-end" sx={{ mt: 1 }}>
            <Pagination
                count={count}
                page={page}
                shape="rounded"
                renderItem={(item) => <PaginationItem component={Link} to={`?page=${item.page}`} {...item} />}
            />
        </Stack>
    );
};

export default ListPagination;
