// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CreateBaseDeviceForm from "./CreateBaseDeviceForm";
import { AddIcon } from "../Icons";
import useTranslation from "../../hooks/usetranslation";
import useUser from "../../hooks/useuser";

const CreateBaseDevice: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const {isReadUser}= useUser();

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Button onClick={handleOpen} variant="contained" sx={{ mr: "auto" }} size="large" startIcon={<AddIcon />} disabled={isReadUser}>
                {t("base_device_new")}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CreateBaseDeviceForm onClose={handleClose} />
            </Dialog>
        </>
    );
};

export default CreateBaseDevice;
